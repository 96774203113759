<template lang="html">

  <div class="">

    <line-chart :data="chartData"  :library="chartOptions"> </line-chart>

    <bar-chart :data="chartData"  :library="chartOptions"></bar-chart>


    <!-- <column-chart :data="[['Sun', 32], ['Mon', 46], ['Tue', 28]]"></column-chart>
    <column-chart :data="[['Sun', 32], ['Mon', 46], ['Tue', 28]]"     :stacked="true"></column-chart> -->


    <!-- <area-chart :data="{'2021-01-01': 11, '2021-01-02': 6}"></area-chart>

    <scatter-chart :data="[[174.0, 80.0], [176.5, 82.3]]" xtitle="Size" ytitle="Population"></scatter-chart>

    <pie-chart :data="chartData"> </pie-chart>



     -->

  </div>

</template>

<script>

export default{

  data(){
    return{
      chartData:{
        'neuro':36,
        'e': 55,
        'o': 66,
        'u': 77,
        's': 19,
      },
      chartOptions: {

        scales: {
          yAxes : [{
            ticks : {
              max : 196,
              min : -1
            }
          }]
        },
        title: {
          display: true,
          text: 'Test title'
      }
      },

    }
}
}
</script>

<style lang="css" scoped>
</style>
